import React from "react";
import Card from "react-bootstrap/Card";
import { ImBookmark } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Oğuzhan ÖZÇELİK </span>
            from <span className="purple"> Izmir, Turkey.</span>
            <br />My interests are in Front End Engineering, Back End Engineering and I love to create beautiful and performant products with delightful user experiences.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImBookmark /> Guitar
            </li>
            <li className="about-activity">
              <ImBookmark /> Basketball
            </li>
            <li className="about-activity">
              <ImBookmark /> Cycling
            </li>
          </ul> 

          <p style={{ color: "#f2d823" }}>
            "Working doesn't mean getting tired and sweating. It is imperative to make maximum use of science, technique and all kinds of civilized inventions according to the requirements of the time."{" "}
          </p>
          <footer className="blockquote-footer">Mustafa Kemal Atatürk</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
